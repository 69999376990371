import cx from 'classnames';
import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AffiliateContext } from '../../contexts/AffiliateContext';
import { useSmoothScroll } from '../../hooks/useSmoothScroll';
import { Button2 } from '../button2';
import { ContactFormNew } from '../forms/contact-form-new';
import one from './1.svg';
import two from './2.svg';
import ava from './ava.png';
import ellipse from './ellipse.svg';
import marker from './marker.svg';
import styles from './nutritionist.module.css';

export const NutritionistSection = () => {
  const scroll = useSmoothScroll()
  const { t } = useTranslation()

  const openOrderForm = selector => {
    scroll.animateScroll(document.getElementById(selector))
  }

  const affiliateContext = useContext(AffiliateContext);
  const { isAffiliate } = affiliateContext.affiliate;

  return (
    <section className={styles.nutritionistWrap}>
      <h3 className="fancyUnderlineText fancyUnderlineText--orange sectionTitleNew text-center">
        <Trans i18nKey="nutritionist.title">
        Immer <span>unter Aufsicht</span> unserer Ernährungsberaterin
        </Trans>
      </h3>

      <div className={styles.nutritionist}>
        <div className={styles.nutritionistImg}>
          <div className={styles.avatar}>
            <img className={styles.avatarImg} src={ava} alt="avatar" />
            <div className={styles.avatarInfo}>
              <div className={styles.avatarInfoName}>
                <span>Jitka Dokoupilová</span>
              </div>
              <div className={styles.avatarInfoPosition}>
                <img src={marker} alt="marker" />
                <span>Expert nutritionist</span>
                <img src={marker} alt="marker" />
              </div>
            </div>
          </div>
          <img
            className={styles.nutritionistImgEllipse}
            src={ellipse}
            alt="img"
          />
        </div>
        <div className={styles.nutritionistInfo}>
          <div className={styles.nutritionistInfoList}>
            <p className={styles.nutritionistInfoListItem}>
              <Trans i18nKey="nutritionist.text1">
              Ich <span className={styles.textBold}>arbeite als Ernährungsberater</span> bei NutritionPro und Ernährung ist mein ganzes Leben! Ich habe den Studiengang Lebensmittelanalyse an der Universität für Chemie und Technologie in Prag und den Studiengang Ernährungstherapeut an der Ersten Medizinischen Fakultät der Karlsuniversität absolviert. Ich berate meine Kunden seit mehr {" "}
                <span className={styles.textBold}>als 15 Jahren</span> und habe in dieser Zeit über {" "}
              <span className={styles.textBold}>1.500</span>Kunden betreut.
              </Trans>
            </p>
            <p className={styles.nutritionistInfoListItem}>
              <Trans i18nKey="nutritionist.text2">
              Nutzen Sie Mahlzeitendosen, wissen aber nicht, wie Sie am Wochenende essen sollen? Sind Sie unsicher, wie viel Wasser Sie zu Ihren Mahlzeitendosen trinken sollten? Möchten Sie wissen, welche Energiezufuhr Sie benötigen? Haben Sie weitere Fragen zur Ernährung oder wollen Sie einfach nur loslegen? Wenn Sie sich über irgendetwas nicht sicher sind, {" "}
                <span className={styles.textBold}>bin ich für Sie da,</span> um alles abzuklären.
              </Trans>
            </p>
          </div>
          <div className={styles.nutritionistForm}>
            <div className={cx(styles.discountInfo, styles.formWrap)}>
              <ContactFormNew
                form_name={"konzultace-dietolog"}
                themeLight={true}
                horizontal={true}
                btnText={t("forms.contactFormNewCTA")}
              />
              </div>
          </div>
          <div className={styles.nutritionistPrompt}>
            <span className={styles.nutritionistPromptOrder}>
              <span className={styles.textBold}>{t("nutritionist.promptOrderText1")}</span> {t("nutritionist.promptOrderText2")}<br></br>
              <span className={styles.textBold}>{t("nutritionist.promptOrderText3")}</span>{" "}
              {t("nutritionist.promptOrderText4")}
            </span>
            <div className={styles.nutritionistPromptImg}>
              <img className={styles.imgOne} src={one} alt="img" />
              <img src={two} alt="img" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
